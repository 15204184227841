$primary: #2ea9e0;
$secondary: #72353c;
$warning: #f9ec25;
$info: #2ea9e0;
//#70308f
$danger: #e1272d;
$dark: #000000;

$sidebar-dark-bg: #111111;

@import "~@coreui/coreui/scss/coreui";

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.fade {
  width: 100%;
}

.c-header-nav .c-header-nav-link {
  padding-right: 0;
}

.c-header-nav-items {
  margin-right: 0 !important;
}
